import React, { Fragment, useState } from "react";
import { Table } from "reactstrap";

import {
  ColumnFiltersState,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";

interface TableContainerProps {
  columns?: any;
  data?: any;
  divClassName?: any;
  tableClass?: any;
  theadClass?: any;
  isBordered?: boolean;
  isGlobalFilter?: boolean;
  isPagination?: boolean;
  paginationWrapper?: string;
  SearchPlaceholder?: string;
  pagination?: string;
  handleUserClick?: any;
  buttonClass?: string;
  buttonName?: string;
  isAddButton?: boolean;
  isCustomPageSize?: boolean;
  isJobListGlobalFilter?: boolean;
  handleFilterChange?: any;
  currentPage?: number;
  totalPages?: number;
  setCurrentPage?: (page: number) => void;
}

const TableContainer = ({
  columns,
  data,
  tableClass,
  divClassName,
  isBordered,

}: TableContainerProps) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const table = useReactTable({
    columns,
    data,
    
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const {
    getHeaderGroups,
    getRowModel,

  } = table;

   return (
      <Fragment>
        <div className={divClassName ? divClassName : "table-responsive"}>
          <Table hover className={tableClass} bordered={isBordered}>
            <thead>
              {getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id} colSpan={header.colSpan} className={`${header.column.columnDef.enableSorting ? "sorting sorting_desc" : ""}`}>
                      {header.isPlaceholder ? null : (
                        <React.Fragment>
                          <div
                            {...{
                              className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {
                              {
                                asc: '',
                                desc: '',
                              }
                              [header.column.getIsSorted() as string] ?? null}
                          </div>
                        </React.Fragment>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
  
            <tbody>
              {getRowModel().rows.map(row => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Fragment>
    );
  };

export default TableContainer;
