import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../../helpers/api_helper";
import { toast } from "react-toastify";

const api = new APIClient();

export const deleteMyAccount = createAsyncThunk(
  "delete_account/deleteMyAccount",
  async (userId: string) => {
    try {
      const response = await api.delete(`/api/v1/users/${userId}`, {});
      toast.success("Your account has been deleted", { autoClose: 2000 })
      return response;
    } catch (error) {
      console.error("Error while deleting account ", error);
      toast.error("Failed to delete account", { autoClose: 2000 });
      throw error;
    }
  }
);