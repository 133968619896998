import { Link } from "react-router-dom";
import React from "react";

const Paginations = ({
  perPageData,
  setPerPage,
  totalDataCount,
  totalPages,
  currentPage,
  setCurrentPage,
  isShowingPageLength,
  paginationDiv,
  paginationClass,
}: any) => {
  const handleClick = (e: any) => {
    setCurrentPage(Number(e.target.id));
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const renderPageNumbers = () => {
    const boundaryPagesToShow = 3;
    const pagesToShow: any = [];

    if (totalPages <= boundaryPagesToShow * 2 + 1) {
      for (let i = 1; i <= totalPages; i++) {
        pagesToShow.push(i);
      }
    } else {
      const iterationCount =
        currentPage <= boundaryPagesToShow ||
          currentPage > totalPages - boundaryPagesToShow
          ? boundaryPagesToShow
          : boundaryPagesToShow - 1;

      for (let i = 1; i <= iterationCount; i++) {
        pagesToShow.push(i);
      }

      if (currentPage > boundaryPagesToShow) {
        pagesToShow.push("...");
      }

      if (
        currentPage >= boundaryPagesToShow + 1 &&
        currentPage <= totalPages - boundaryPagesToShow
      ) {
        pagesToShow.push(currentPage);
      }

      if (currentPage <= totalPages - iterationCount) {
        pagesToShow.push("...");
      }

      for (let i = totalPages - iterationCount + 1; i <= totalPages; i++) {
        pagesToShow.push(i);
      }
    }

    return pagesToShow;
  };

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between">
        {isShowingPageLength && (
          <div>
            <div className="text-muted">
              Showing{" "}
              <span className="fw-semibold">
                {totalDataCount / (perPageData * currentPage) < 1
                  ? totalDataCount % perPageData
                  : perPageData}
              </span>{" "}
              of <span className="fw-semibold">{totalDataCount}</span> entries
            </div>
          </div>
        )}
        <div className={`${paginationDiv}`}>
          <ul className={paginationClass}>
            <li className={`page-item ${currentPage <= 1 ? "disabled" : ""}`}>
              <Link className="page-link" to="#" onClick={handlePrevPage}>
                <i className="mdi mdi-chevron-left"></i>
              </Link>
            </li>

            {renderPageNumbers().map((item: any, index: number) =>
              item === "..." ? (
                <li key={index} className="page-item">
                  <span className="page-link">...</span>
                </li>
              ) : (
                <li
                  key={index}
                  className={`page-item ${currentPage === item ? "active" : ""
                    }`}
                >
                  <Link
                    className="page-link"
                    to="#"
                    id={item}
                    onClick={handleClick}
                  >
                    {item}
                  </Link>
                </li>
              )
            )}

            <li
              className={`page-item ${currentPage >= totalPages ? "disabled" : ""
                }`}
            >
              <Link className="page-link" to="#" onClick={handleNextPage}>
                <i className="mdi mdi-chevron-right"></i>
              </Link>
            </li>
          </ul>
        </div>
        <div className="d-flex align-items-center">
          <div className="text-muted me-2">Size:</div>
          <select
            id="perPageSelect"
            className="form-select"
            value={perPageData}
            onChange={(e) => setPerPage(Number(e.target.value))}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
          </select>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Paginations;
