import React, { useEffect, useRef, useCallback } from "react";
//Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";

import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import withRouter from "../../Components/Common/withRouter";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useAppSelector, useAppDispatch } from "slices";
import { getChats, getContacts } from "slices/chats/thunk";
import { fetchRequestedSessions } from "slices/session/thunk";
import { getPublicConfig } from "slices/thunk";
import { isEmpty } from "lodash";
const SidebarContent = (props: any) => {
  const dispatch = useAppDispatch();
  const unreadMessages = useAppSelector(state => state.Chats.unreadMessages);
  const contacts = useAppSelector(state => state.Chats.contacts);
  const pendingRequest = useAppSelector(state => state.SessionList.requestedSessions);
  const contactId = props.contactId;

  const selectPublicConfigProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      publicConfig: global.publicConfig,
    })
  );

  const { publicConfig } = useSelector(selectPublicConfigProperties);

  useEffect(() => {
    dispatch(getChats());
    dispatch(getContacts());
    dispatch(fetchRequestedSessions(contactId));
  }, [dispatch, contactId]);

  useEffect(() => {
    if (isEmpty(publicConfig)) {
      dispatch(getPublicConfig());
    }
  }, [dispatch, publicConfig]);
  const hasPendingRequests = pendingRequest && pendingRequest.length > 0;
  const ref = useRef<any>();
  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.length && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname;
    let matchingMenuItem = null;
    const ul: any = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);
  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }
  const selectProfileProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      user: global.user,
      permissions: global.permissions,
    })
  );

  const { permissions, user } = useSelector(selectProfileProperties);

  const showAdminNav = permissions?.reduce((acc: any, curr: any) => {
    if (curr.resource === "org-admin") {
      return true;
    }
    return acc;
  }, false);

  const renderFAQs = () => {
    if (user.type === "BOTH") {
      return (
        <>
          <li>
            <Link to="/mentee/mentee-guidelines">
              {props.t("Mentee Guidelines")}
            </Link>
          </li>
          <li>
            <Link to="/mentor/mentor-guidelines">
              {props.t("Mentor Guidelines")}
            </Link>
          </li>
        </>
      )
    }
    else if (user.type === "MENTEE") {
      return (
        <li>
          <Link to="/mentee/mentee-guidelines">
            {props.t("Mentee Guidelines")}
          </Link>
        </li>
      );
    } else if (user.type === "MENTOR") {
      return (
        <li>
          <Link to="/mentor/mentor-guidelines">
            {props.t("Mentor Guidelines")}
          </Link>
        </li>
      );
    }
  }
  const sortedContacts = [...contacts].sort((a, b) => a.firstName.localeCompare(b.firstName));

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
              <ul className="sub-menu">
                {showAdminNav && (
                  <li>
                    <Link to="/dashboard/admin">
                      <i className="bx bxs-user-detail"></i>
                      <span>{props.t("Admin")}</span>
                    </Link>
                  </li>
                )}
                {["MENTEE", "BOTH"].includes(user.type) && (
                  <li>
                    <Link to="/dashboard/mentee">
                      <i className="bx bxs-user-pin"></i>
                      <span>{props.t("Mentee")}</span>
                    </Link>
                  </li>
                )}
                {["MENTOR", "BOTH"].includes(user.type) && (
                  <li>
                    <Link to="/dashboard/mentor">
                      <i className="bx bx-user-pin"></i>
                      <span>{props.t("Mentor")}</span>
                    </Link>
                  </li>
                )}

              </ul>
            </li>

            <li>
              <Link to="/profile">
                <i className="bx bx-user"></i>
                <span>{props.t("Profile")}</span>
              </Link>
            </li>
            {showAdminNav && (
              <>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bxs-user-detail"></i>
                    <span>{props.t("Admin")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/admin-members">
                        {props.t("Manage Members")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin-invited-users">
                        {props.t("Invited Users")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin-invite-users">
                        {props.t("Invite Users")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin-org-sessions">
                        {props.t("Org Sessions")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-paperclip"></i>
                    <span>{props.t("Pairing")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/pair-mentor-mentee">
                        {props.t("Pair Mentor-Mentee")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/existing-pairs">
                        {props.t("Existing Pairs")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}
            <li>
              <Link to="/#">
                <i className='bx bxs-calendar'></i>
                <span>{props.t("Sessions")}
                  <span className="has-arrow"></span>
                  {hasPendingRequests && (
                    <i className="bx bx-bell bx-tada float-end text-danger fs-10"></i>
                  )}
                </span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/sessions">
                    <i className='bx bxs-calendar-event'></i>
                    {props.t("All")}
                  </Link>
                </li>
                {sortedContacts.map(contact => (
                  <li key={contact.id}>
                    <Link to={`/sessions/${contact.id}`}>
                      {contact.firstName} {contact.lastName} x {user.firstName}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <Link to="/notes">
                <i className="bx bx-notepad"></i>
                <span>{props.t("Notes")}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/feedbacks?tab=COMPLETED">
                <i className="bx bx-task"></i>
                <span>{props.t("Feedbacks")}</span>
              </Link>
            </li>
            {(user.type === 'MENTEE' || user.type === 'BOTH') && (
              <>
                {publicConfig && publicConfig.organizationSettings && !publicConfig.organizationSettings.disableExploreMentors && (
                  <li>
                    <Link to="/explore-mentors">
                      <i className="bx bx-search"></i>
                      <span>{props.t("Explore Mentors")}</span>
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/suggested-mentors">
                    <i className='bx bxs-user-account'></i>
                    <span>{props.t("Assigned Mentors")}</span>
                  </Link>
                </li>
              </>
            )}

            <li>
              <Link to="/chat">
                <i className="bx bx-chat"></i>
                <span>
                  {props.t("Chat")}
                  {unreadMessages && (
                    <i className="bx bx-bell bx-tada float-end text-danger fs-10 me-3"></i>
                  )}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow ">
                <i className='bx bx-dots-horizontal-rounded' ></i>
                <span>{props.t("More")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/faq">
                    {props.t("FAQ")}
                  </Link>
                </li>
                {renderFAQs()}
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(SidebarContent));