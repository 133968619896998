import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "slices";
import { getNotes, deleteNote } from "slices/notes/thunk";
import { Card, CardBody, CardHeader, Row, Col, Modal, ModalBody } from "reactstrap";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Paginations from "Components/Common/Pagination";

dayjs.extend(utc);
dayjs.extend(timezone);

interface NotesListProps {
  onEditNote: (note: any) => void;
}

const NotesList: React.FC<NotesListProps> = ({ onEditNote }) => {
  const dispatch = useAppDispatch();
  type NotesState = any;

  const { notes, isLoading } = useAppSelector((state: { Notes: NotesState }) => state.Notes);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const PERPAGE = 10;
  const { user: currentUser } = useAppSelector((state) => state.Global);
  
  useEffect(() => {
    dispatch(getNotes({
      perPage: PERPAGE,
      page: currentPage,
    }));
  }, [dispatch, currentPage]);


  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState<string | null>(null);

  const handleDeleteClick = (noteId: string) => {
    setNoteToDelete(noteId);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = () => {
    if (noteToDelete) {
      dispatch(deleteNote(noteToDelete))
        .then(() => {
          dispatch(getNotes({
            perPage: PERPAGE,
            page: currentPage,
          }));
        });
      setShowDeleteModal(false);
    }
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
    setNoteToDelete(null);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-3">
      <Row>
        {Array.isArray(notes?.notes) && notes.notes.length > 0 ? (
          notes.notes.map((note: any) => (
            <Col key={note.id} lg={12} className="mb-0">
              <Card outline className="border">
                <CardHeader className="bg-transparent d-flex justify-content-between align-items-center px-3">
                  <p className="my-0 text-black" style={{ fontWeight: 500 }}>
                    {note.title}
                  </p>
                </CardHeader>
                <CardBody className="m-0 py-0 px-3">
                  <div className="d-flex gap-3 align-items-center my-2">
                    <div className="d-flex align-items-center">
                      <i className="bx bx-calendar font-size-16" />
                      <span className="ms-1">                       
                        {dayjs.utc(note.updatedAt || note.createdAt, "DD MMMM, YYYY").tz(currentUser.timezone).format("DD MMMM, YYYY")}
                      </span>
                    </div>
                    <div className="d-flex align-items-center">
                      <i className="bx bx-time font-size-16" />
                      <span className="ms-1">
                        {dayjs.utc(note.updatedAt || note.createdAt).tz(currentUser.timezone).format("h:mm A")}
                      </span>
                    </div>
                    <div className="d-flex align-items-center">
                      <i className="bx bx-edit-alt" style={{ cursor: "pointer" }} onClick={() => onEditNote(note)}></i>

                    </div>
                    <div className="d-flex align-items-center">
                      <i className="bx bx-trash" style={{ cursor: "pointer" }} onClick={() => handleDeleteClick(note.id)}></i>

                    </div>

                  </div>

                  <div className="my-2">
                    <p className="mb-0">{note.content}</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))
        ) : (
          <div className="text-center">No notes available</div>
        )}
      </Row>
      <Modal isOpen={showDeleteModal} toggle={handleCloseModal} centered={true}>
        <ModalBody className="py-3 px-5">
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <i className="mdi mdi-alert-circle-outline" style={{ fontSize: "9em", color: "orange" }} />
                <h2>Are you sure?</h2>
                <h4>{"You won't be able to revert this!"}</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center mt-3">
                <button
                  type="button"
                  className="btn btn-success btn-lg ms-2"
                  onClick={handleDeleteConfirm}
                >
                  Yes, delete it!
                </button>
                <button
                  type="button"
                  className="btn btn-danger btn-lg ms-2"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Paginations
        perPageData={PERPAGE}
        totalDataCount={notes?.total}
        totalPages={notes?.totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isShowingPageLength={true}
        paginationClass="pagination pagination-rounded"
      />
    </div>
  );
};

export default NotesList;
