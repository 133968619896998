import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import withRouter from "Components/Common/withRouter";
import Breadcrumbs from "Components/Common/Breadcrumb";
import { getOrgSessions } from "../../../slices/admin/thunk";
import { createSelector } from "reselect";
import Spinners from "Components/Common/Spinner";
import Paginations from "Components/Common/Pagination"
import Select from "react-select";
import { toast } from "react-toastify";
import { APIClient } from "../../../helpers/api_helper";
import OrgSessionsTable from "Components/OrgSessionsTable";

const api = new APIClient();

const OrgSessions = () => {
  document.title = "Org Sessions | Mentorgain";

  const selectProperties = createSelector(
    (state: any) => state.Admin,
    (admin) => ({
      orgSessions: admin.orgSessions,
      loading: admin.loading,
    })
  );
  const { orgSessions, loading }: any = useSelector(selectProperties);
  const [isLoading, setLoading] = useState<boolean>(loading);
  const [status, setStatus] = useState<any>([]);
  const [orderBy, setOrderBy] = useState<any>();
  const [order, setOrder] = useState<any>();
  const [skills, setSkills] = useState<any>([]);
  const [allSkills, setAllSkills] = useState<any>();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  const dispatch = useDispatch<any>();

  const statusData: any[] = [
    { label: "Scheduled", value: "scheduled" },
    { label: "Completed", value: "completed" },
    { label: "Canceled", value: "canceled" },
  ];

  const orderByData: any[] = [
    { label: "Mentor Name", value: "mentorName" },
    { label: "Mentee Name", value: "menteeName" },
    { label: "Status", value: "status" },
    { label: "Scheduled At", value: "scheduledAt" },
  ];

  const orderData: any[] = [
    { label: "ASC", value: "asc" },
    { label: "DESC", value: "desc" },
  ];

  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          return (
            <>
              {!cellProps.row.original.img && (
                <div style={{ height: "1.5rem", width: "1.5rem" }}>
                  <span className="avatar-title rounded-2">
                    {cellProps.row.index + (currentPage - 1) * perPage + 1}
                  </span>
                </div>
              )}
            </>
          );
        },
      },
      {
        header: "Mentor Name",
        accessorKey: "mentorName",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Mentee Name",
        accessorKey: "menteeName",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: () => (
          <div style={{ marginLeft: '15px' }}>
            Skills
          </div>
        ),
        accessorKey: "skills",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          return cellProps.row.original.skills.length ? (
            <ul
              style={{
                maxWidth: "350px",
                whiteSpace: "normal",
                margin: 0,
              }}
            >
              {cellProps.row.original.skills.map((skill, index) => (
                <li key={index}>{skill}</li>
              ))}
            </ul>
          ) : (
            <p style={{ paddingLeft: "20px", margin: 0 }}>NA</p>
          );
        },
      },
      {
        header: "Mentor Score",
        accessorKey: "mentorScore",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Mentee Score",
        accessorKey: "menteeScore",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Scheduled At",
        accessorKey: "scheduledAt",
        enableColumnFilter: false,
        enableSorting: false,
      },
    ],
    [currentPage, perPage]
  );

  const handleFilterData = useCallback(() => {
    const filterQuery: any = {};

    if (status.length) {
      filterQuery["filter.status"] = status.map((status) => status.value);
    }

    if (orderBy) {
      filterQuery["filter.orderBy"] = orderBy["value"];
    }

    if (order) {
      filterQuery["filter.order"] = order["value"];
    }

    if (skills) {
      filterQuery["filter.skills"] = skills.map((skill) => skill.value);
    }

    dispatch(
      getOrgSessions({
        filterQuery,
        perPage: perPage,
        page: currentPage,
      })
    );
  }, [dispatch, currentPage, status, orderBy, order, skills, perPage]);

  const fetchSkills = async () => {
    await api
      .get("/api/v1/sessions/skills")
      .then((response: any) => {
        const options = response.map((group: any) => ({
          label: group.title,
          name: group.name,
          options: group.choices.map((choice: any) => ({
            label: choice.text,
            value: choice.value,
          })),
        }));
        setAllSkills(options);
      })
      .catch((error: any) => {
        console.log(error);
        toast.error("Error while fetching skills.");
      });
  };

  useEffect(() => {
    fetchSkills();
  }, []);

  useEffect(() => {
    handleFilterData();
  }, [handleFilterData, perPage]);



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Administration" breadcrumbItem="Org Sessions" />
          <Row>
            <Col lg="12">
              {isLoading ? (
                <Spinners setLoading={setLoading} />
              ) : (
                <Card>
                  <div className="d-flex flex-wrap mt-4 mx-4">
                    <div
                      className="col-6 col-md-2"
                      style={{ marginRight: "25px" }}
                    >
                      <Select
                        name="Status"
                        placeholder="Status"
                        isClearable
                        isMulti
                        options={statusData}
                        onChange={(selectedOptions) => {
                          selectedOptions === null
                            ? setStatus([])
                            : setStatus(selectedOptions);
                          setCurrentPage(1);
                        }}
                        value={status}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          menu: (base) => ({ ...base, maxHeight: 300 }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#fff"
                              : provided.backgroundColor,
                            color: state.isSelected ? "#000" : provided.color,
                          }),
                        }}
                      />
                    </div>
                    <div
                      className="col-6 col-md-5"
                      style={{ marginRight: "25px" }}
                    >
                      <Select
                        classNamePrefix="select2-selection modal-dialog-scrollable modal-dialog"
                        name="features"
                        placeholder="Choose Goals/Skills"
                        options={allSkills}
                        isMulti
                        onChange={(selectedOptions) => {
                          selectedOptions === null
                            ? setSkills([])
                            : setSkills(selectedOptions);
                          setCurrentPage(1);
                        }}
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: "white",
                            cursor: "pointer",
                          }),
                          menu: (provided, state) => ({
                            ...provided,
                            backgroundColor: "white",
                          }),
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          placeholder: (styles) => ({
                            ...styles,
                          }),
                        }}
                        menuPortalTarget={document.body}
                      />
                    </div>
                    <div
                      className="col-6 col-md-2"
                      style={{ marginRight: "25px" }}
                    >
                      <Select
                        name="Order By"
                        placeholder="Order By"
                        isClearable
                        options={orderByData}
                        onChange={(selectedOption) => {
                          selectedOption === null
                            ? setOrderBy("")
                            : setOrderBy(selectedOption);
                          setCurrentPage(1);
                        }}
                        value={orderBy}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          menu: (base) => ({ ...base, maxHeight: 300 }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#fff"
                              : provided.backgroundColor,
                            color: state.isSelected ? "#000" : provided.color,
                          }),
                        }}
                      />
                    </div>
                    <div className="col-6 col-md-2">
                      <Select
                        name="Order"
                        placeholder="Order"
                        isClearable
                        options={orderData}
                        onChange={(selectedOption) => {
                          selectedOption === null
                            ? setOrder("")
                            : setOrder(selectedOption);
                          setCurrentPage(1);
                        }}
                        value={order}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          menu: (base) => ({ ...base, maxHeight: 300 }),
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#fff"
                              : provided.backgroundColor,
                            color: state.isSelected ? "#000" : provided.color,
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <CardBody>
                    <OrgSessionsTable
                      columns={columns}
                      data={orgSessions?.sessions || []}
                      tableClass="table-sm align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                      buttonClass="btn btn-primary btn-sm addContact-modal mb-2 text-white"
                      buttonName="Invite User"
                      currentPage={currentPage}
                      totalPages={orgSessions.totalPages}
                      setCurrentPage={setCurrentPage}

                    />
                    <Paginations
                      perPageData={perPage}
                      setPerPage={setPerPage}
                      totalDataCount={orgSessions?.totalSessions}
                      totalPages={orgSessions?.totalPages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      isShowingPageLength={true}
                      paginationClass="pagination pagination-rounded"
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(OrgSessions);
