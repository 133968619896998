import { createSlice } from "@reduxjs/toolkit";
import { deleteMyAccount } from "./thunk"; 

interface DeleteAccountState {
  loading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: DeleteAccountState = {
  loading: false,
  error: null,
  success: false,
};

const deleteAccountSlice = createSlice({
  name: "DeleteAccount",
  initialState,
  reducers: {
    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteMyAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(deleteMyAccount.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = null;
      })
      .addCase(deleteMyAccount.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export const { resetState } = deleteAccountSlice.actions;

export default deleteAccountSlice.reducer;
