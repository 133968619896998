import React, { useEffect } from "react";
import withRouter from "../../Components/Common/withRouter";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";

//Import mini card widgets
import MiniCards from "../Dashboard/mini-card";

//Import Images
import profile1 from "../../assets/images/profile-img.png";

import user1 from "../../assets/images/users/user_profile.svg";

import AvailableSlots from "../../Components/AvailableSlots";
import { Link } from "react-router-dom";
import {
  fetchProfile,
  fetchProfileAnalytics,
} from "../../slices/profile/thunk";
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import countryList from "react-select-country-list";
import { useAppSelector } from "slices";
const countries = countryList().getData();

export const getCountryName = (countryCode) => {
  const country = countries.find((c) => c.value === countryCode);
  return country ? country.label : countryCode;
};

const MentorMenteeProfile = (props: any) => {
  //meta title
  document.title = "Profile | Mentorgain";

  const { id } = props.router.params;
  const dispatch = useDispatch<any>();
  const { location } = props.router;
  const queryParams = new URLSearchParams(location.search);

  const viewingAs = queryParams.get("viewingAs");

  const selectUserProfile = createSelector(
    (state: any) => state.UserProfile,
    (profile) => ({
      userProfile: profile?.userProfile,
    })
  );

  const { userProfile } = useSelector(selectUserProfile);
  const { userAnalytics } = useAppSelector((state) => state.UserProfile);

  useEffect(() => {
    let userType: string | undefined = undefined;
    if (viewingAs === "MENTOR") userType = "MENTEE";
    if (viewingAs === "MENTEE") userType = "MENTOR";
    dispatch(fetchProfile(id));
    dispatch(fetchProfileAnalytics({ id, userType }));
  }, [dispatch, id, viewingAs]);

  const miniCards = [
    {
      title: "Total sessions",
      iconClass: "bx bx-check-circle",
      text: userAnalytics?.totalSessions,
    },
    {
      title: "Total session hours ",
      iconClass: "bx bx-hourglass",
      text: userAnalytics?.totalHours,
    },
    {
      title: "Overall Satisfaction",
      iconClass: "bx bx-hourglass",
      text: userAnalytics?.ratings?.["overall-satisfaction"] || 'NA',
    },
  ];

  const transformName = (name: string) => {
    return name
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const renderMentorBackgroundAndExpertise = () => {
    const mentorFields = userProfile?.mentorField;
    const MENTOR_FIELDS_TO_DISPLAY = [
      "i-am-described-by-others-as",
      "problem-solving-skills",
      "i-would-like-to-mentor",
      "my-personal-experiences",
    ];

    return (
      <>
        {viewingAs !== "MENTOR" && MENTOR_FIELDS_TO_DISPLAY.map((field: string) => {
          if (
            mentorFields?.hasOwnProperty(field) &&
            !isEmpty(mentorFields[field])
          ) {
            return (
              <div
                key={field}
                className="d-flex justify-content-between align-items-center"
                style={{
                  padding: "1.2rem 0",
                  borderBottom: "1px solid #e3e3e3",
                }}
              >
                <span className="text-muted">{transformName(field)}</span>
                <div className="d-flex flex-wrap justify-content-end align-items-center gap-1">
                  {typeof mentorFields[field] === "string" ? (
                    <Badge className="badge-soft-primary font-size-12">
                      {transformName(mentorFields[field])}
                    </Badge>
                  ) : (
                    mentorFields[field].map((value: string, index: number) => (
                      <Badge
                        key={index}
                        className="badge-soft-primary font-size-12"
                      >
                        {transformName(value)}
                      </Badge>
                    ))
                  )}
                </div>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };

  const renderMenteeBackgroundAndExpertise = () => {
    const menteeFields = userProfile?.menteeField;
    const MENTEE_FIELDS_TO_DISPLAY = [
      "adaptability-and-flexibility",
      "my-key-experiences",
      "problem-solving-skills",
      "leadership-skills",
      "why-i-need-a-mentor",
    ];
    return (
      <>
        { viewingAs !== "MENTEE" && MENTEE_FIELDS_TO_DISPLAY.map((field: string) => {
          if (
            menteeFields?.hasOwnProperty(field) &&
            !isEmpty(menteeFields[field])
          ) {
            return (
              <div
                key={field}
                className="d-flex justify-content-between align-items-center"
                style={{
                  padding: "1.2rem 0",
                  borderBottom: "1px solid #e3e3e3",
                }}
              >
                <span className="text-muted">{transformName(field)}</span>
                <div className="d-flex flex-wrap justify-content-end align-items-center gap-1">
                  {typeof menteeFields[field] === "string" ? (
                    <Badge className="badge-soft-primary font-size-12">
                      {transformName(menteeFields[field])}
                    </Badge>
                  ) : (
                    menteeFields[field].map((value: string, index: number) => (
                      <Badge
                        key={index}
                        className="badge-soft-primary font-size-12"
                      >
                        {transformName(value)}
                      </Badge>
                    ))
                  )}
                </div>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };

  const renderBackgroundAndExpertise = () => {
    const isMentor =
      userProfile?.type === "BOTH" || userProfile?.type === "MENTOR";
    const isBoth = userProfile?.type === "BOTH";

    if (isBoth) {
      return (
        <>
          {renderMentorBackgroundAndExpertise()}
          {renderMenteeBackgroundAndExpertise()}
        </>
      );
    } else if (isMentor) {
      return renderMentorBackgroundAndExpertise();
    } else {
      return renderMenteeBackgroundAndExpertise();
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="User" breadcrumbItem="Profile" />

          <Row>
            <Col xl={4}>
              {userProfile && (
                <>
                  <Card className="overflow-hidden">
                    <div className="bg-primary-subtle">
                      <Row>
                        <Col xs={7}>
                          <div className="text-primary p-3">
                          </div>
                        </Col>
                        <Col xs={5} className="align-self-end">
                          <img src={profile1} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <Row>
                        <Col sm={8}>
                          <div className="avatar-md profile-user-wid mb-4">
                            <img
                              src={userProfile.avatarUrl || user1}
                              alt=""
                              className="img-thumbnail rounded-circle"
                            />
                          </div>
                          <h5 className="font-size-15 text-truncate">
                            {userProfile.firstName} {userProfile.lastName}
                          </h5>
                          <div className="d-flex justify-content-start align-items-center">
                            <i className="bx bx-briefcase-alt me-1"></i>
                            <p className="text-muted mb-0 text-truncate text-uppercase">
                              {userProfile.title}{" "}
                            </p>
                          </div>
                        </Col>
                        <Col sm={4}>
                          {userProfile.linkedInProfile && (
                            <Link
                              to={userProfile.linkedInProfile}
                              className="d-flex justify-content-end align-items-end h-100 pb-1"
                            >
                              <i
                                className="bx bxl-linkedin-square font-size-24"
                                style={{ cursor: "pointer" }}
                              ></i>
                            </Link>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <CardTitle className="mb-3">
                        Personal Information
                      </CardTitle>
                      <p className="text-muted mb-3">{userProfile.bio}</p>
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Full Name :</th>
                              <td>
                                {userProfile.firstName} {userProfile.lastName}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Location :</th>
                              <td>{userProfile.location}</td>
                            </tr>
                            <tr>
                              <th scope="row">Country :</th>
                              <td>{getCountryName(userProfile.country)}</td>
                            </tr>
                            <tr>
                              <th scope="row">Timezone :</th>
                              <td>{userProfile?.timezone}</td>
                            </tr>
                            <tr>
                              <th scope="row">Language :</th>
                              <td>{userProfile.language}</td>
                            </tr>
                            <tr>
                              <th scope="row">Experience :</th>
                              <td>{userProfile.yearsOfExperience} Years</td>
                            </tr>
                            <tr>
                              <th scope="row">LinkedIn :</th>
                              {userProfile.linkedInProfile ? (
                                <td>
                                  <Link
                                    to={userProfile.linkedInProfile}
                                    className="d-flex justify-content-end align-items-end h-100 pb-1"
                                  >
                                    LinkedIn
                                  </Link>
                                </td>
                              ) : (
                                <td>-</td>
                              )}
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </>
              )}
            </Col>

            <Col xl={8}>
              <Row>
                {(miniCards || [])?.map((card: any, key: number) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>

              {(userProfile?.type === "BOTH" ||
                userProfile?.type === "MENTOR") && (
                <Card>
                  <AvailableSlots mentorId={id} />
                </Card>
              )}

              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Background & Expertise</CardTitle>
                  <div
                    style={{
                      border: "1px solid #e3e3e3",
                      borderRadius: "1rem",
                      padding: "0px 1.5rem",
                    }}
                  >
                    {renderBackgroundAndExpertise()}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(MentorMenteeProfile);
