import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container, Input, Form, Button } from 'reactstrap';
import List from './List';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import Spinners from 'Components/Common/Spinner';
import { applyMultipleFilters, getAllMentors, getMatchedMentors } from 'slices/mentor/thunk';
import { APIClient } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import Select from "react-select";
import { getPublicConfig } from "slices/thunk";
import { isEmpty } from 'lodash';

const api = new APIClient();

// TODO: Fix confusing naming of functions: assigned, suggested, matched etc.
// TODO: Fix filtering

const MentorList = (props: any) => {
  document.title = "Mentors List";

  const dispatch = useDispatch<any>();
  const { type = "all" } = props;

  const selectAllMentors = createSelector(
    (state: any) => state.Mentor,
    (mentor) => ({
      allMentorList: mentor.allMentorList,
      loading: false
    })
  );

  const selectPublicConfigProperties = createSelector(
    (state: any) => state.Global,
    (global) => ({
      publicConfig: global.publicConfig,
    })
  );

  const { publicConfig } = useSelector(selectPublicConfigProperties);

  useEffect(() => {
    if (isEmpty(publicConfig)) {
      dispatch(getPublicConfig());
    }
  }, [dispatch, publicConfig]);

  const fetchSuggestedMentors = async () => {
    const response = await api.get("/api/v1/search/suggested", {});
    if (response) {
      setFilteredMentorList(response);
    } else {
      toast.error("Failed to fetch suggested mentors");
    }
  }

  const { allMentorList, loading } = useSelector(selectAllMentors);
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [isLoading, setLoading] = useState<boolean>(loading);
  const [filteredMentorList, setFilteredMentorList] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [personalExperiences, setPersonalExperiences] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState<any>(null);
  const [selectedDesignation, setSelectedDesignation] = useState<any>(null);
  const [selectedPersonalExperience, setSelectedPersonalExperience] = useState<any>([]);

  useEffect(() => {
    dispatch(getAllMentors(type));
    api.get('/api/v1/org-configs', { keys: "designations,departments" }).then((response: any) => {
      const fetchedDepartments = response.departments.map((department: any) => ({ label: department.title, value: department.value }));
      const fetchedDesignations = response.designations.map((designation: any) => ({ label: designation.title, value: designation.value }));
      setDepartments(fetchedDepartments);
      setDesignations(fetchedDesignations);
    }).catch((error: any) => {
      console.error(error);
      toast.error("Failed to fetch departments and designations");
    });

    api.get('/api/v1/onboarding/profile/forms/mentor', null).then((response: any) => {
      const form = response.form;
      form.pages.forEach((page: any) => {
        const formData = page?.elements.find((element: any) => element.name === "my-personal-experiences");
        if (formData) {
          const options = formData?.choices.map((choice: any) => ({ label: choice.text, value: choice.value }));
          setPersonalExperiences(options);
        }
      });
    }).catch((error: any) => {
      console.error(error);
      toast.error("Failed to fetch personal experiences");
    });
  }, [dispatch, type]);

  useEffect(() => {
    if (type === "suggested") {
      fetchSuggestedMentors();
    } else {
      setFilteredMentorList(allMentorList);
    }
  }, [allMentorList, type]);

  const fetchMatchedMentors = () => {
    dispatch(getMatchedMentors());
  }

  const handleSearchMentor = (value: any) => {
    const search = value.toLowerCase();
    setSearchTerm(search);
    if (search === "") {
      const filterTypes: any[] = [];
      const filterValues: any[] = [];
      if (selectedDepartment) {
        filterTypes.push("department");
        filterValues.push(selectedDepartment.value);
      }
      if (selectedDesignation) {
        filterTypes.push("designation");
        filterValues.push(selectedDesignation.value);
      }
      if (selectedPersonalExperience.length > 0) {
        filterTypes.push("surveyPersonalExperience");
        filterValues.push(selectedPersonalExperience.map((option: any) => option.value));
      }
      dispatch(applyMultipleFilters({ filterTypes, filterValues }));
    } else {
      const filterTypes = ["name"];
      const filterValues = [search];
      if (selectedDepartment) {
        filterTypes.push("department");
        filterValues.push(selectedDepartment.value);
      }
      if (selectedDesignation) {
        filterTypes.push("designation");
        filterValues.push(selectedDesignation.value);
      }
      if (selectedPersonalExperience.length > 0) {
        filterTypes.push("surveyPersonalExperience");
        filterValues.push(selectedPersonalExperience.map((option: any) => option.value));
      }
      dispatch(applyMultipleFilters({ filterTypes, filterValues }));
    }
  }

  const handleDepartmentChange = (selectedOption) => {
    const filterTypes: any[] = [];
    const filterValues: any[] = [];
    if (selectedOption === null) {
      setSelectedDepartment(null);
    } else {
      filterTypes.push("department");
      filterValues.push(selectedOption.value);
      setSelectedDepartment(selectedOption);
    }
    if (searchTerm !== "") {
      filterTypes.push("name");
      filterValues.push(searchTerm);
    }
    if (selectedDesignation) {
      filterTypes.push("designation");
      filterValues.push(selectedDesignation.value);
    }
    if (selectedPersonalExperience.length > 0) {
      filterTypes.push("surveyPersonalExperience");
      filterValues.push(selectedPersonalExperience.map((option: any) => option.value));
    }
    dispatch(applyMultipleFilters({ filterTypes, filterValues }));
  }

  const handleDesignationChange = (selectedOption) => {
    const filterTypes: any[] = [];
    const filterValues: any[] = [];
    if (selectedOption === null) {
      setSelectedDesignation(null);
    } else {
      filterTypes.push("designation");
      filterValues.push(selectedOption.value);
      setSelectedDesignation(selectedOption);
    }
    if (searchTerm !== "") {
      filterTypes.push("name");
      filterValues.push(searchTerm);
    }
    if (selectedDepartment) {
      filterTypes.push("department");
      filterValues.push(selectedDepartment.value);
    }
    if (selectedPersonalExperience.length > 0) {
      filterTypes.push("surveyPersonalExperience");
      filterValues.push(selectedPersonalExperience.map((option: any) => option.value));
    }
    dispatch(applyMultipleFilters({ filterTypes, filterValues }));
  }

  const handlePersonalExperienceChange = (selectedOption: any) => {
    const filterTypes: any[] = [];
    const filterValues: any[] = [];
    if (selectedOption === null) {
      setSelectedPersonalExperience([]);
    } else {
      filterTypes.push("surveyPersonalExperience");
      filterValues.push(selectedOption.map((option: any) => option.value));
      setSelectedPersonalExperience(selectedOption);
    }
    if (searchTerm !== "") {
      filterTypes.push("name");
      filterValues.push(searchTerm);
    }
    if (selectedDepartment) {
      filterTypes.push("department");
      filterValues.push(selectedDepartment.value);
    }
    if (selectedDesignation) {
      filterTypes.push("designation");
      filterValues.push(selectedDesignation.value);
    }
    dispatch(applyMultipleFilters({ filterTypes, filterValues }));
  }

  return (
    <>
      <div className="page-content">
            <Container fluid>
              {type !== "suggested" && (
                <Card className="job-filter">
                  <CardBody>
                    <Form action="#">
                      <div className="full-width-container">
                        <div className="row">
                          <div className="col-lg-9 col-12">
                            <div className="row">
                              <div className="col-12 col-md-6 mb-3">
                                <div className="position-relative">
                                  <Input
                                    type="text"
                                    id="search"
                                    autoComplete="off"
                                    placeholder="Search by Name"
                                    value={searchTerm}
                                    onChange={(e) => handleSearchMentor(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6 mb-3">
                                <Select
                                  name="Personal Experience"
                                  placeholder="Personal Experiences"
                                  isClearable
                                  isMulti
                                  options={personalExperiences}
                                  onChange={handlePersonalExperienceChange}
                                  value={selectedPersonalExperience}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    menu: (base) => ({ ...base, maxHeight: 300 }),
                                    option: (provided, state) => ({
                                      ...provided,
                                      backgroundColor: state.isSelected
                                        ? "#fff"
                                        : provided.backgroundColor,
                                      color: state.isSelected ? "#000" : provided.color,
                                    }),
                                  }}
                                />
                              </div>
                              <div className="col-12 col-md-6">
                                <div className="d-block d-md-none mb-3">
                                  <Select
                                    name="designation"
                                    placeholder="Designation"
                                    isClearable
                                    options={designations}
                                    onChange={handleDesignationChange}
                                    value={selectedDesignation}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                      menu: (base) => ({ ...base, maxHeight: 300 }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isSelected
                                          ? "#fff"
                                          : provided.backgroundColor,
                                        color: state.isSelected ? "#000" : provided.color,
                                      }),
                                    }}
                                  />
                                </div>
                                <div className="d-none d-md-block mb-1">
                                  <Select
                                    name="designation"
                                    placeholder="Designation"
                                    isClearable
                                    options={designations}
                                    onChange={handleDesignationChange}
                                    value={selectedDesignation}
                                    menuPortalTarget={document.body}
                                    styles={{
                                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                      menu: (base) => ({ ...base, maxHeight: 300 }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isSelected
                                          ? "#fff"
                                          : provided.backgroundColor,
                                        color: state.isSelected ? "#000" : provided.color,
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-6 mb-1">
                                <Select
                                  name="department"
                                  placeholder="Department"
                                  isClearable
                                  options={departments}
                                  onChange={handleDepartmentChange}
                                  value={selectedDepartment}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    menu: (base) => ({ ...base, maxHeight: 300 }),
                                    option: (provided, state) => ({
                                      ...provided,
                                      backgroundColor: state.isSelected
                                        ? "#fff"
                                        : provided.backgroundColor,
                                      color: state.isSelected ? "#000" : provided.color,
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-12 d-flex justify-content-center align-items-center">
                            <div className="d-none d-lg-flex flex-column align-items-center me-3">
                              <div className="line-separator separator m-0"></div>
                              <div>OR</div>
                              <div className="line-separator separator m-0"></div>
                            </div>
                            <div className="w-100">
                              <div className="py-1 d-block d-lg-none">
                                <div className="separator">OR</div>
                              </div>
                              <Button
                                type="button"
                                color="primary"
                                className="w-100"
                                onClick={fetchMatchedMentors}
                              >
                                Match Me!
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              )}
              {isLoading ? (
                <Spinners setLoading={setLoading} />
              ) : (
                <List listData={filteredMentorList} />
              )}
            </Container>
      </div>
    </>
  );
}

export default MentorList;

