import { createSlice } from "@reduxjs/toolkit";
import {
  getNotes,
  getNotesById,
  createNote,
  updateNote,
  deleteNote,
} from "./thunk";

interface Note {
  id: string;
  title: string;
  content: string;
  priority?: string;
  createdAt?: string;
  updatedAt?: string;
}

interface NotesState {
  notes: Note[];
  noteDetails: Note | null;
  isLoading: boolean;
  error: string | null;
  total: 0;
  perPage: 10;
  page: 1;
  totalPages: 0;
}

const initialState: NotesState = {
  notes: [],
  noteDetails: null,
  isLoading: false,
  error: null,
  total: 0,
  perPage: 10,
  page: 1,
  totalPages: 0,
};

const NotesSlice = createSlice({
  name: "Notes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotes.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(getNotes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notes = action.payload;
    });

    builder.addCase(getNotes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || "Failed to fetch notes.";
    });

    builder.addCase(getNotesById.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getNotesById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.noteDetails = action.payload;
    });
    builder.addCase(getNotesById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || "Failed to fetch note.";
    });

    builder.addCase(createNote.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createNote.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createNote.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || "Failed to create note.";
    });

    builder.addCase(updateNote.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateNote.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateNote.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || "Failed to update note.";
    });

    builder.addCase(deleteNote.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(deleteNote.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(deleteNote.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || "Failed to delete note.";
    });
  },
});

export default NotesSlice.reducer;
