import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, Row, Col } from "reactstrap";
import { useAppDispatch, useAppSelector } from "slices";
import { deleteMyAccount } from "slices/auth/delete_account/thunk";
import { useNavigate } from "react-router-dom";
import { setLogoutAction } from "slices/global/reducer";

const DeleteAccount = (props: any) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useAppDispatch();
  const { loading, error, success } = useAppSelector((state) => state.DeleteAccount);
  const { user: currentUser } = useAppSelector((state) => state.Global);
  const navigate = useNavigate();

  const handleDeleteConfirm = () => {
    if (currentUser.id) {
      dispatch(deleteMyAccount(currentUser.id));
      dispatch(setLogoutAction());
    }
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  useEffect(() => {
    if (success) {
      setShowDeleteModal(false);
    }
  }, [success, navigate]);

  return (
    <React.Fragment>
      <div>
        <Row className="justify-content-center">
          <Col md={6}>
            <h4 className="text-center mb-2 mt-3">Delete Account</h4>
            <div className="p-2 text-center mb-3">
              <strong>Note:</strong> Are you sure you want to delete your mentorship account? This action cannot be undone.
            </div>
            <div className="d-flex justify-content-center">
              <Button
                color="secondary"
                onClick={handleDeleteClick}
                disabled={loading}
              >
                {loading ? "Deleting..." : "Delete My Account"}
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      <Modal isOpen={showDeleteModal} toggle={handleCloseModal} centered={true}>
        <ModalBody className="py-3 px-5">
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <i
                  className="mdi mdi-alert-circle-outline"
                  style={{ fontSize: "9em", color: "orange" }}
                />
                <h2>Are you absolutely sure?</h2>
                {error && <div className="text-danger">{error}</div>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center mt-3">
                <button
                  type="button"
                  className="btn btn-danger btn-lg ms-2 mb-3"
                  onClick={handleDeleteConfirm}
                  disabled={loading}
                >
                  {loading ? "Deleting..." : "Yes, delete my account!"}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary btn-lg ms-2 mb-3"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteAccount;
