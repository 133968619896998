import React, { useState } from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  CardBody,
  Card
} from "reactstrap";
import withRouter from "Components/Common/withRouter";
import { createNote, updateNote, getNotes } from "slices/notes/thunk";
import NotesList from "./NoteList";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAppDispatch } from "slices";

const Notes = () => {
  document.title = "Notes | Mentorgain";

  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [noteId, setNoteId] = useState<string | null>(null);
  const [currentPage] = useState<number>(1);
  const PERPAGE = 10;

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    resetForm();
  };

  const validationSchema = Yup.object({
    title: Yup.string()
      .required("Title is required")
      .max(250, "Title cannot exceed 250 characters"),
    content: Yup.string().required("Content is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      content: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const noteData = {
        title: values.title,
        content: values.content,
      };

      if (noteId) {
        dispatch(updateNote({ noteId, noteData })).then(() => {
          dispatch(getNotes({
            perPage: PERPAGE,
            page: currentPage,
          }));
        });
      } else {
        dispatch(createNote(noteData)).then(() => {
          dispatch(getNotes({
            perPage: PERPAGE,
            page: currentPage,
          }));
        });
      }
      toggleModal();
    },
  });

  const resetForm = () => {
    setNoteId(null);
    formik.resetForm();
  };

  const handleEditNote = (note: any) => {
    setNoteId(note.id);
    formik.setValues({
      title: note.title,
      content: note.content,
    });
    setIsModalOpen(true);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Card className="bg-transparent">
          <CardBody>
            <div className="d-flex justify-content-end mt-0">
              <Button type="button" color="primary" onClick={toggleModal}>
                <i className="mdi mdi-plus me-1" />
                Add Note
              </Button>
            </div>
            <Modal isOpen={isModalOpen} toggle={toggleModal} size="md">
              <ModalHeader toggle={toggleModal}>
                {noteId ? "Update Note" : "Add a New Note"}
              </ModalHeader>
              <ModalBody>
                <form onSubmit={formik.handleSubmit}>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label for="noteTitle">Title</Label>
                      <Input
                        type="text"
                        id="noteTitle"
                        placeholder="Enter title..."
                        {...formik.getFieldProps("title")}
                        invalid={!!(formik.touched.title && formik.errors.title)}
                      />
                      {formik.touched.title && formik.errors.title && (
                        <div className="text-danger">{formik.errors.title}</div>
                      )}
                    </div>

                    <div className="mb-3">
                      <Label for="noteContent">Content</Label>
                      <Input
                        type="textarea"
                        id="noteContent"
                        placeholder="Enter note content..."
                        rows="4"
                        {...formik.getFieldProps("content")}
                        invalid={!!(formik.touched.content && formik.errors.content)}
                      />
                      {formik.touched.content && formik.errors.content && (
                        <div className="text-danger">{formik.errors.content}</div>
                      )}
                    </div>
                  </Col>

                  <div className="d-flex justify-content-end">
                    <Button
                      color="primary"
                      type="submit"
                      className="me-2"
                      disabled={!(formik.isValid && formik.dirty)}
                    >
                      {noteId ? "Update Note" : "Save Note"}
                    </Button>
                    <Button color="secondary" type="button" onClick={toggleModal}>
                      Cancel
                    </Button>
                  </div>
                </form>
              </ModalBody>
            </Modal>
          </CardBody>

          <NotesList onEditNote={handleEditNote} />
        </Card>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Notes);
