import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../helpers/api_helper";
import { toast } from "react-toastify";

const api = new APIClient();

export const getNotes = createAsyncThunk(
  "notes/getNotes",
  async (paginationData: any) => {
    try {
      const response = await api.get("api/v1/notes", paginationData);
      return response;
    } catch (error) {
      console.error("Error fetching notes", error);
      toast.error("Failed to fetch notes data");
      throw error;
    }
  }
);

export const getNotesById = createAsyncThunk(
  "notes/getNotesById",
  async (noteId: string) => {
    try {
      const response = await api.get(`api/v1/notes/${noteId}`);
      return response;
    } catch (error) {
      console.error("Error fetching note by ID", error);
      toast.error("Failed to fetch note data");
      throw error;
    }
  }
);

export const createNote = createAsyncThunk(
  "notes/createNote",
  async (noteData: { title: string; content: string; priority?: string }) => {
    try {

      const dataToSend = {
        ...noteData,
        priority: noteData.priority || 'medium'
      };

      const response = await api.create("api/v1/notes", dataToSend);

      toast.success("Note created successfully", { autoClose: 2000 });
      return response.data;
    } catch (error) {
      console.error("Error while creating a note", error);
      toast.error("Failed to create a note");
      throw error;
    }
  }
);


export const updateNote = createAsyncThunk(
  "notes/updateNote",
  async ({ noteId, noteData }: { noteId: string; noteData: { title?: string; content?: string; priority?: string } }) => {
    try {
      const response = await api.update(`api/v1/notes/${noteId}`, noteData);
      toast.success("Note updated successfully", { autoClose: 2000 });
      return response;
    } catch (error) {
      console.error("Error while updating the note", error);
      toast.error("Failed to update the note");
      throw error;
    }
  }
);

export const deleteNote = createAsyncThunk(
  "notes/deleteNote",
  async (id: any, { dispatch }) => {
    try {
      const response = await api.delete(`api/v1/notes/${id}`, {});
      toast.success("Note deleted successfully", { autoClose: 2000 });
      return response;
    } catch (error) {
      console.error("Error while deleting the note", error);
      toast.error("Failed to delete the note");
      throw error;
    }
  }
);
