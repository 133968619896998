import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { UncontrolledTooltip } from "reactstrap";
import { createSelector } from "reselect";
import Spinners from "Components/Common/Spinner";
import InviteUsersTable from "Components/InviteUsersTable";
import Breadcrumbs from "Components/Common/Breadcrumb";
import {
  getInvitedUsers,
  resendInvite,
  deleteInvitedUser
} from "../../../slices/admin/thunk";
import Paginations from "Components/Common/Pagination";

const ManageMembers = () => {
  document.title = "User List | Mentorgain";

  const selectProperties = createSelector(
    (state: any) => state.Admin,
    (admin) => ({
      invitedUsers: admin.invitedUsers,
      loading: admin.loading,
    })
  );

  const { invitedUsers, loading }: any = useSelector(selectProperties);
  const dispatch = useDispatch<any>();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [isLoading, setLoading] = useState<boolean>(loading);
  const [modal, setModal] = useState<boolean>(false);
  const [currentInviteId, setCurrentInviteId] = useState<any>(null);
  const [actionType, setActionType] = useState<'resend' | 'delete' | null>(null);
  const navigate = useNavigate();

  const toggle = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  const openModal = useCallback((id: any, type: 'resend' | 'delete') => {
    setCurrentInviteId(id);
    setActionType(type);
    setModal(true);
  }, []);

  const handleUserClick = useCallback(() => {
    navigate('/admin-invite-users');
  }, [navigate]);

  const handleInviteAction = useCallback(() => {
    if (currentInviteId) {
      if (actionType === 'resend') {
        dispatch(resendInvite(currentInviteId));
      } else if (actionType === 'delete') {
        dispatch(deleteInvitedUser(currentInviteId));
        dispatch(getInvitedUsers({
          perPage,
          page: currentPage,
        }));
      }
      setModal(false);
    }
  }, [currentInviteId, actionType, dispatch, currentPage, perPage]);

  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps: any) => {
          return (
            <>
              {!cellProps.row.original.img ? (
                <div style={{ height: '1.5rem', width: '1.5rem' }}>
                  <span className="avatar-title rounded-2">
                    {cellProps.row.index + (currentPage - 1) * perPage + 1}
                  </span>
                </div>
              ) : (
                <div>
                  <img
                    className="rounded-2 avatar-xs"
                    src={cellProps.row.original.img}
                    alt="Profile Logo"
                  />
                </div>
              )}
            </>
          );
        },
      },
      {
        header: "First Name",
        accessorKey: "firstName",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Last Name",
        accessorKey: "lastName",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Email",
        accessorKey: "email",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Actions",
        accessorKey: "actions",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          return (
            <div className="d-flex gap-3">
              <i
                className="mdi mdi-email-sync-outline font-size-20"
                id="resendTooltip"
                onClick={() => openModal(cellProps.row.original.id, 'resend')}
              />
              <UncontrolledTooltip placement="top" target="resendTooltip">
                Resend Invite
              </UncontrolledTooltip>
              <i
                className="mdi mdi-delete-outline font-size-20"
                id="deleteTooltip"
                onClick={() => openModal(cellProps.row.original.id, 'delete')}
              />
              <UncontrolledTooltip placement="top" target="deleteTooltip">
                Delete Invite
              </UncontrolledTooltip>
            </div>
          );
        },
      }
    ],
    [openModal, currentPage, perPage]
  );

  useEffect(() => {
    dispatch(getInvitedUsers({
      perPage: perPage,
      page: currentPage,
    }));
  }, [dispatch, currentPage, perPage]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Administration" breadcrumbItem="Invited Users" />
          <Row>
            <Col lg="12">
              {isLoading ? (
                <Spinners setLoading={setLoading} />
              ) : (
                <Card>
                  <CardBody>
                    <InviteUsersTable
                      columns={columns}
                      data={invitedUsers.invites || []}
                      tableClass="table-sm align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                      buttonClass="btn btn-primary btn-sm addContact-modal mb-2 text-white"
                      buttonName="Invite User"
                      handleUserClick={handleUserClick}
                      currentPage={currentPage}
                      totalPages={invitedUsers.invites?.totalPages}
                      setCurrentPage={setCurrentPage}
                    />
                    <Paginations
                      perPageData={perPage}
                      setPerPage={setPerPage}
                      totalDataCount={invitedUsers?.total}
                      totalPages={invitedUsers?.totalPages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      isShowingPageLength={true}
                      paginationClass="pagination pagination-rounded"
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {actionType === 'resend' ? 'Resend Invite' : 'Delete Invite'}
        </ModalHeader>
        <ModalBody>
          <p>
            {actionType === 'resend'
              ? 'Are you sure you want to resend the invite to this user?'
              : 'Are you sure you want to delete this invite?'}
          </p>
          <div className="text-right d-flex justify-content-end">
            <button
              type="button"
              className={`btn ${actionType === 'resend' ? 'btn-primary' : 'btn-danger'} me-2`}
              onClick={handleInviteAction}
            >
              {actionType === 'resend' ? 'Resend' : 'Delete'}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={toggle}
            >
              Close
            </button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ManageMembers;
